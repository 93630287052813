import _ from 'lodash'
import numeral from 'numeral'

export const allKeys = {
  institutionName: 'Institution Name',
  unitid: 'unitid',
  CTOTALT: 'Total Graduates (2020)',
  CTOTALM: 'Male',
  CTOTALW: 'Female',
  CWHITT: 'White',
  CASIAT: 'Asian',
  CBKAAT: 'Black',
  CAIANT: 'AIAN (American Indian or Alaska Native)',
  CHISPT: 'Hispanic or Latino',
  CNHPIT: 'NHPI (Native Hawaiian or Other Pacific Islander)',
  C2MORT: 'Two or more races',
  CUNKNT: 'Race/ethnicity unknown',
  CNRALT: 'International Student',
  CWHITM: 'Male:White',
  CWHITW: 'Female:White',
  CASIAM: 'Male:Asian',
  CASIAW: 'Female:Asian',
  CBKAAM: 'Male:Black',
  CBKAAW: 'Female:Black',
  CAIANM: 'Male:AIAN (American Indian or Alaska Native)',
  CAIANW: 'Female:AIAN (American Indian or Alaska Native)',
  CHISPM: 'Male:Hispanic or Latino',
  CHISPW: 'Female:Hispanic or Latino',
  CNHPIM: 'Male:NHPI (Native Hawaiian or Other Pacific Islander)',
  CNHPIW: 'Female:NHPI (Native Hawaiian or Other Pacific Islander)',
  C2MORM: 'Male:Two or more races',
  C2MORW: 'Female:Two or more races',
  CUNKNM: 'Male:Race/ethnicity unknown',
  CUNKNW: 'Female:Race/ethnicity unknown',
  CNRALM: 'Male:International Student',
  CNRALW: 'Female:International Student',
}

const minWidths = {
  institutionName: 250,
  CTOTALT: 170,
}

export const columns = usePercentages =>
  Object.keys(allKeys)
    .filter(key => key !== 'unitid')
    .map(key => ({
      Header: allKeys[key],
      accessor: row =>
        _.isNumber(row[key])
          ? usePercentages && key !== 'CTOTALT'
            ? `${numeral(row[key]).format()}%`
            : numeral(row[key]).format()
          : row[key], // String-based value accessors!
      id: key,
      fixed: key === 'institutionName' ? 'left' : undefined,
      className: key !== 'institutionName' ? '-numeric-column' : 'inst-column',
      ...(minWidths[key] ? { minWidth: minWidths[key] } : { minWidth: 130 }),
    }))
