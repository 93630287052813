import React, { useState, useEffect } from 'react'

import ReactTable from 'react-table-6'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'
import Map from './Map'
import TopSchoolsBar from './TopSchoolsBar'

import { columns } from './columnMappings'
import formatFilters from './formatFilters'

import authenticatedRequest from '../lib/authenticatedRequest'
import numberWithCommas from '../lib/numberWithCommas'

const PAGE_SIZE = 20

export default function SummaryData(props) {
  /*************************** lifecycle methods ***************************/
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [metadata, setMetadata] = useState({ totalStudents: null, totalShools: null })

  const { attachSchoolInfoToUnitIDs, cipCodes, filters } = props

  useEffect(() => {
    // if page, sort, filters, or programs changed, we want to refetch
    fetchSummaryData()

    async function fetchSummaryData() {
      // show the loading overlay
      setLoading(true)

      const formattedFilters = formatFilters(filters)

      // fetch data with the correct route
      const res = await authenticatedRequest({
        extension: '/university-explorer/majors/bySchool',
        responseType: 'json',
        method: 'get',
        params: {
          completionsCategories: ['CTOTALT'],
          returnAll: true,
          sortBy: 'CTOTALT',
          cipCodes,
          filters: JSON.stringify(formattedFilters),
        },
      })

      let totalStudents = 0
      if (res.data.totalCounts) {
        totalStudents = res.data.totalCounts.CTOTALT
      } else {
        totalStudents = res.data.rows.reduce((acc, row) => acc + row.CTOTALT, 0)
      }

      setMetadata({ totalStudents, totalSchools: res.data.rows.length })

      // setData(_.reverse(attachSchoolInfoToUnitIDs(res.data.rows)));
      setData(attachSchoolInfoToUnitIDs(res.data.rows.reverse()))
      setLoading(false)
    }
  }, [attachSchoolInfoToUnitIDs, cipCodes, filters])

  // what about when liberal is also selected?
  const hardCodeTop250Num = Math.max(
    ...[0].concat(filters.filter(f => f[0] === 'usNewsRanking').map(f => f[1]))
  )

  const hardCodeLiberalNum = Math.max(
    ...[0].concat(filters.filter(f => f[0] === 'top100Liberal').map(f => 100))
  )

  return (
    <div className="summary-data">
      <div className="metacardRow">
        <div className="box metacard">
          <div className="content">
            <h2>
              {numberWithCommas(hardCodeTop250Num + hardCodeLiberalNum || metadata.totalSchools)}
            </h2>
            <p className="has-text-weight-medium">Universities fit this criteria</p>
          </div>
        </div>
        <div className="box metacard">
          <div className="content">
            <h2>{numberWithCommas(metadata.totalStudents)}</h2>
            <p className="has-text-weight-medium">Total Graduates (2020)</p>
          </div>
        </div>
        <div className="box metacard">
          <div className="content">
            <h2>
              {numberWithCommas(
                hardCodeTop250Num ||
                  Math.min(
                    data.filter(
                      d =>
                        !_.isNil(d.usNewsRanking) && d.usNewsRanking > 0 && d.usNewsRanking <= 250
                    ).length,
                    250
                  )
              ) || 0}
            </h2>
            <p className="has-text-weight-medium">
              of these universities are Top 250 Nationally Ranked universities
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '60%' }}>
          <Map
            setTooltipContent={setContent}
            wayup={props.wayup || false}
            data={data.filter(
              school =>
                school.coordinates &&
                school.coordinates.latitude &&
                school.coordinates.longitude &&
                school.usBased
            )}
          />
          <ReactTooltip>{content}</ReactTooltip>
        </div>
        <div className="bar-chart-container">
          <h3 className="has-text-weight-medium is-size-5">Top 10 Universities</h3>
          <div className="bar-chart">
            <TopSchoolsBar wayup={props.wayup || false} data={data.slice(-10)} />
          </div>
        </div>
      </div>
    </div>
  )
}

// const mapStateToProps = state => ({
//   trending: [], // state.reports.trending,
//   new: [], // state.reports.new,
//   // searchQuery: state.searchbar.searchQuery
// });
//
// export default connect(
//   mapStateToProps,
//   {}
// )(UniversityExplorer);
