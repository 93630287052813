import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import _ from 'lodash'

import './TopSchoolsBar.sass'

const CustomTickLabel = tick => (
  <g transform={`translate(${tick.x - 10},${tick.y})`}>
    <switch>
      <foreignObject className="schoolbar-label-container" x="0" y="0" width="100" height="35">
        <div className="tick-label">
          <p>{tick.value}</p>
        </div>
      </foreignObject>

      <text x="0" y="0">
        Your SVG viewer cannot display html.
      </text>
    </switch>
  </g>
)

export default function TopSchoolsBar({ data, wayup }) {
  return (
    <ResponsiveBar
      data={data.map(d => ({
        ...d,
        institutionName: _.truncate(d.institutionNameAlias || d.institutionName || d.unitid, {
          length: 30,
        }),
      }))}
      tooltip={({ id, value, color }) => (
        <strong style={{ color }}>Total Graduates (2020): {value}</strong>
      )}
      keys={['CTOTALT']}
      layout="horizontal"
      indexBy="institutionName"
      margin={{ top: 0, right: 0, bottom: 32, left: 110 }}
      padding={0.2}
      colors={wayup ? ['#16D8ED'] : ['#28ba9a']}
      axisRight={null}
      gridXValues={5}
      axisBottom={{
        tickSize: 0,
        tickPadding: 0,
        tickRotation: 0,
        tickValues: 5,
        legend: 'Total Graduates (2020)',
        legendPosition: 'middle',
        legendOffset: 25,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        renderTick: CustomTickLabel,
      }}
      enableLabel={false}
      motionStiffness={90}
      motionDamping={15}
      markers={[
        {
          axis: 'x',
          value: 0,
          lineStyle: { stroke: '#4E4E4E', strokeWidth: 2 },
        },
      ]}
      enableGridY={false}
      enableGridX
      animate
    />
  )
}
