import React, { useEffect, createRef, useState } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import bulmaCollapsible from '../lib/bulmaCollapsible/js'
import { FaCaretUp, FaCaretDown } from 'react-icons/fa'
import usStates from '../lib/usStates'

import './Sidebar.sass'

const targetFilter = {
  label: 'Target Schools',
  filter: ['targetSchools'],
  _id: 'targetSchools',
}

const distanceFilter = (zipCode, distance) => ({
  label: 'Miles from Zip Code',
  filter: ['withinDistance', zipCode, distance],
  _id: 'withinDistance',
})

const uniTypeFilter = {
  title: 'University Type (Degree)',
  _id: '_uniType',
  options: [
    {
      _id: '_uniType:2yr',
      label: 'Two Year (Associates Degree)',
      filter: ['twoYearFourYear', 'Two-year'],
    },
    {
      _id: '_uniType:4yr',
      label: 'Four Year (Bachelors Degree)',
      filter: ['twoYearFourYear', 'Four-year'],
    },
  ],
}

const filters = [
  {
    title: 'US News Ranking',
    options: [
      { label: 'Top 250 National', filter: ['usNewsRanking', 250] },
      { label: 'Top 100 National', filter: ['usNewsRanking', 100] },
      { label: 'Top 50 National', filter: ['usNewsRanking', 50] },
      { label: 'Top 100 Liberal', filter: ['top100Liberal', true] },
    ],
  },
  {
    title: 'State',
    options: usStates.map(state => ({
      label: state,
      filter: ['targetStates', state],
    })),
  },
  {
    title: 'Undergrad Enrollment',
    options: [
      { label: '0-1,999', filter: ['undergraduateEnrollment', [0, 1999]] },
      { label: '2,000-4,999', filter: ['undergraduateEnrollment', [2000, 4999]] },
      { label: '5,000-9,999', filter: ['undergraduateEnrollment', [5000, 9999]] },
      { label: '10,000-24,999', filter: ['undergraduateEnrollment', [10000, 24999]] },
      { label: '25,000-49,999', filter: ['undergraduateEnrollment', [25000, 49999]] },
      { label: '50,000+', filter: ['undergraduateEnrollment', [50000, 10 ** 6]] },
    ],
  },
].map(filter => ({
  ...filter,
  options: filter.options.map(opt => ({
    ...opt,
    _id: _.snakeCase(`${filter.title} ${opt.label}`),
  })),
}))

const Sidebar = props => {
  const sidebarBlocks = createRef()

  const [distance, setDistance] = useState('')
  const [zip, setZip] = useState('')
  const [liveFilterOpts, setLiveFilterOpts] = useState(filters)

  useEffect(() => {
    // Update the document title using the browser API
    bulmaCollapsible.attach('.is-collapsible', {
      container: sidebarBlocks.current,
    })
  })

  const { selectedFilters, toggleFilter, setFilter, removeFilter, user } = props

  useEffect(() => {
    // Update the document title using the browser API
    const dellUser = user && user.email && user.email.includes('@dell.com')

    if (dellUser) {
      const stateFilter = liveFilterOpts.find(filter => filter.title === 'State')
      if (stateFilter) {
        const newOptions = [
          {
            label: 'States with a Dell office',
            filter: ['targetStates', 'Massachusetts', 'Oklahoma', 'Texas', 'Tennessee', 'Utah'],
          },
          {
            label: 'Most likely to move to',
            filter: [
              'targetStates',
              'Indiana',
              'Michigan',
              'Ohio',
              'Illinois',
              'Pennsylvania',
              'Florida',
            ],
          },
        ]
          .map(opt => ({
            ...opt,
            _id: _.snakeCase(`${stateFilter.title} ${opt.label}`),
          }))
          .filter(opt => !stateFilter.options.find(existingOpt => existingOpt._id === opt._id))

        const stateFilterIndex = liveFilterOpts.findIndex(filter => filter.title === 'State')
        setLiveFilterOpts([
          ...liveFilterOpts.slice(0, stateFilterIndex),
          {
            ...stateFilter,
            options: [...newOptions, ...stateFilter.options],
          },
          ...liveFilterOpts.slice(stateFilterIndex + 1),
        ])
      }
    }
  }, [user])

  // maintain state variable for both inputs, on blur of either check if they both have values and if so add it,
  // if not remove it

  const removeDistanceFilterIfEmpty = () => !distance && !zip && removeFilter(distanceFilter())

  return (
    <div className="ue-sidebar">
      <nav className="level flex-wrap" style={{ marginTop: -5 }}>
        <div className="level-left" style={{ marginTop: 5 }}>
          <div className="level-item">
            <p className="is-size-6 sidebar-title is-uppercase is-family-primary">
              University Filters
            </p>
          </div>
        </div>
        <div className="level-right" style={{ marginTop: 5 }}>
          <div className="level-item">
            <button
              onClick={() => props.resetFilters()}
              className="button is-text is-small reset-filter-button"
            >
              Reset
            </button>
          </div>
        </div>
      </nav>
      {!props.wayup && (
        <nav className="box level is-mobile">
          <div className="level-left">
            <div className="level-item">
              <p className="is-size-6">{targetFilter.label}</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item field">
              <input
                type="checkbox"
                id="switchTargetSchools"
                name="switchTargetSchools"
                className="switch is-rounded target-filters-switch"
                onChange={() => toggleFilter(targetFilter)}
                checked={!!selectedFilters.find(selected => selected._id === targetFilter._id)}
              />
              <label htmlFor="switchTargetSchools"></label>
            </div>
          </div>
        </nav>
      )}

      {/* Uni Type Filter */}
      <div key={uniTypeFilter.title} className="box">
        <nav className="level is-mobile is-marginless">
          <div className="level-left">
            <div className="level-item">
              <p className="is-size-6">{uniTypeFilter.title}</p>
            </div>
          </div>
        </nav>
        <div
          id={`sector-card-${_.kebabCase(uniTypeFilter.title)}`}
          className=" sidebar-block"
          data-parent="sidebar-blocks"
          data-allow-multiple="true"
        >
          <div className="block-separator" />
          <div className="content" style={{ overflow: 'hidden' }}>
            {uniTypeFilter.options.map((filter, i) => (
              <div key={uniTypeFilter.title} className="field checkbox-field">
                <input
                  type="checkbox"
                  className="is-checkradio is-small has-background-color"
                  id={`sector-checkbox-${_.kebabCase(filter._id)}`}
                  name={`sector-checkbox-${_.kebabCase(filter._id)}`}
                  checked={!!selectedFilters.find(selected => selected._id === filter._id)}
                  onChange={() => toggleFilter(filter)}
                />
                <label htmlFor={`sector-checkbox-${_.kebabCase(filter._id)}`}>{filter.label}</label>
              </div>
            ))}
          </div>
        </div>
      </div>

      <nav className="box is-mobile">
        <div className="level-left">
          <div className="level-item">
            <p className="is-size-6">{distanceFilter().label}</p>
          </div>
        </div>
        <div className="level-left flex-wrap distance-buttons">
          <div className="level-item">
            <input
              type="number"
              placeholder="Miles"
              value={distance}
              onChange={ev => setDistance(ev.target.value)}
              size={5}
              onBlur={removeDistanceFilterIfEmpty}
            />
          </div>
          <div className="level-item">
            <input
              type="text"
              placeholder="Zip Code"
              value={zip}
              onChange={ev => setZip(ev.target.value)}
              size={10}
              onBlur={removeDistanceFilterIfEmpty}
            />
          </div>
          <div className="level-item">
            <button
              className="button is-text is-small reset-filter-button"
              onClick={() => setFilter(distanceFilter(zip, distance))}
            >
              Search
            </button>
          </div>
          <div className="level-item">
            <button
              className="button is-text is-small reset-filter-button"
              onClick={() => {
                setDistance('')
                setZip('')
                removeFilter(distanceFilter())
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </nav>
      <div id="sidebar-blocks" ref={sidebarBlocks}>
        {liveFilterOpts.map(filter => (
          <div key={filter.title} className="box">
            <nav className="level is-mobile is-marginless">
              <div className="level-left">
                <div className="level-item">
                  <p className="is-size-6">{filter.title}</p>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item collapse-indicator">
                  {/*<p className="indicator-label">
                    <span className="has-text-weight-bold">2</span> selected
                  </p>*/}
                  <a
                    href={`#sector-card-${_.kebabCase(filter.title)}`}
                    className="indicator-button"
                    data-action="collapse"
                    aria-label="more options"
                  >
                    <FaCaretDown className="indicator-icon" />
                  </a>
                </div>
              </div>
            </nav>
            <div
              id={`sector-card-${_.kebabCase(filter.title)}`}
              className="is-collapsible is-clipped sidebar-block"
              data-parent="sidebar-blocks"
              data-allow-multiple="true"
            >
              <div className="block-separator" />
              <div className="content">
                {filter.options.map((filter, i) => (
                  <div key={filter.title} className="field checkbox-field">
                    <input
                      type="checkbox"
                      className="is-checkradio is-small has-background-color"
                      id={`sector-checkbox-${_.kebabCase(filter._id)}`}
                      name={`sector-checkbox-${_.kebabCase(filter._id)}`}
                      checked={!!selectedFilters.find(selected => selected._id === filter._id)}
                      onChange={() => toggleFilter(filter)}
                    />
                    <label htmlFor={`sector-checkbox-${_.kebabCase(filter._id)}`}>
                      {filter.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default connect(
  state => ({
    user: state.user.user,
  }),
  {}
)(Sidebar)
