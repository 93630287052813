import React, { useState } from 'react'
import _ from 'lodash'
import { IconContext } from 'react-icons'
import { GoSearch } from 'react-icons/go'
import { FaCaretDown } from 'react-icons/fa'

import { graphql, StaticQuery } from 'gatsby'
import OutsideAlerter from '../lib/OutsideAlerter'

import './TopBar.sass'

const filterPrograms = (programs, filterQuery) =>
  programs
    .filter(program => program.searchString.includes(filterQuery.toLowerCase()))
    .map(program => ({
      ...program,
      items: program.items.filter(subProgram =>
        subProgram.label.toLowerCase().includes(filterQuery)
      ),
      unfilteredItems: program.items,
    }))

const TopBar = ({ groups, cipCodes: topLevelCipCodes, setCipCodes, wayup }) => {
  /*************************** lifecycle methods ***************************/
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [programFilter, setProgramFilter] = useState('')
  const [tempCipCodes, setTempCipCodes] = useState([])

  const onTextChange = ev => setProgramFilter(ev.target.value)

  const onToggleCipCode = cipCode =>
    setTempCipCodes(
      tempCipCodes.includes(cipCode)
        ? _.without(tempCipCodes, cipCode)
        : _.concat(tempCipCodes, cipCode)
    )

  const onToggleList = (fullList, toToggle) =>
    _.intersection(fullList, toToggle).length === toToggle.length
      ? _.without(fullList, ...toToggle)
      : _.uniq(_.concat(fullList, toToggle))

  // if all cipCodes are in tempCipCodes, remove, else add all
  const onToggleGroupInTemp = cipCodes => setTempCipCodes(onToggleList(tempCipCodes, cipCodes))
  const onToggleGroupInTopLevel = codes => setCipCodes(onToggleList(topLevelCipCodes, codes))

  const activeItems = _.flatten(
    groups.map(group => {
      const activeItems = group.items
        .filter(item => topLevelCipCodes.includes(item.value))
        .map(item => ({ label: item.label, toRemove: [item.value] }))
      if (activeItems.length === group.items.length) {
        return { label: group.label, toRemove: group.items.map(item => item.value) }
      }

      return activeItems
    })
  )

  if (!activeItems.length) {
    activeItems.push({ label: 'All Programs', toRemove: [] })
  }

  return (
    <div className="ue-top-bar">
      <div className="is-flex ue-filter-bar">
        <OutsideAlerter disabled={!dropdownOpen} handleClickOutside={() => setDropdownOpen(false)}>
          <div className={`dropdown ${dropdownOpen ? 'is-active' : ''}`}>
            <div className="box dropdown-trigger field is-paddingless is-marginless">
              <p className="control has-icons-left">
                <input
                  className="input is-static"
                  type="text"
                  placeholder="Filter Programs"
                  onFocus={() => setDropdownOpen(true)}
                  onChange={onTextChange}
                />
                <span className="icon is-small is-left">
                  <IconContext.Provider value={{ className: 'search-icon' }}>
                    <GoSearch />
                  </IconContext.Provider>
                </span>
              </p>
              {/*
              <button className="button" onClick={() => setDropdownOpen(!dropdownOpen)}>
                <span>Content</span>
                <span className="icon is-small">
                  <i className="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
              */}
            </div>
            <div className="dropdown-menu" id="search-menu" role="menu">
              <div className="dropdown-content">
                <div className="main-dropdown-content">
                  {filterPrograms(groups, programFilter || '').map(group => (
                    <DropdownItem
                      key={group.label}
                      {...group}
                      onToggleCipCode={onToggleCipCode}
                      onToggleGroup={onToggleGroupInTemp}
                      activeCipCodes={tempCipCodes}
                    />
                  ))}
                </div>
                <div className="dropdown-item dropdown-footer buttons is-flex">
                  <button
                    className="button is-success is-primary apply-button"
                    onClick={() => setCipCodes(tempCipCodes)}
                  >
                    Apply
                  </button>
                  {!wayup && (
                    <button className="button is-medium is-text advanced-button">
                      Advanced search
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </OutsideAlerter>
      </div>
      <div className="active-row" style={{ display: 'flex', flexDirection: 'row' }}>
        <p className="has-text-weight-medium">Showing:</p>
        <div className="tags are-medium">
          {activeItems.map(item => (
            <span key={item.label} className="tag is-rounded is-primary">
              {item.label}{' '}
              {item.toRemove.length ? (
                <button
                  className="delete"
                  onClick={() => onToggleGroupInTopLevel(item.toRemove)}
                ></button>
              ) : null}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

const DropdownItem = ({
  label,
  items,
  unfilteredItems,
  onToggleCipCode,
  onToggleGroup,
  activeCipCodes,
}) => {
  const [expanded, setExpanded] = useState(false)

  const values = items.map(i => i.value)
  const allValues = unfilteredItems.map(i => i.value)
  const groupSelected = _.intersection(activeCipCodes, values).length === values.length
  const escapedLabel = _.snakeCase(label)

  return (
    <div className="dropdown-item">
      <div className="level is-marginless">
        <div className="level-left">
          <div className="level-item">
            <div className="field checkbox-field">
              <input
                type="checkbox"
                className="is-checkradio is-small has-background-color"
                id={`search-checkbox-${escapedLabel}`}
                name={`search-checkbox-${escapedLabel}`}
                checked={groupSelected}
                onChange={() => onToggleGroup(allValues)}
              />
              <label htmlFor={`search-checkbox-${escapedLabel}`}>{label}</label>
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item" onClick={() => setExpanded(!expanded)}>
            <span>{items.length} results</span>
            <FaCaretDown className="expand-icon" />
          </div>
        </div>
      </div>
      {expanded && (
        <div className="dropdown-item-details">
          {items.map(item => (
            <div key={item.value} className="dropdown-subitem">
              <div className="field checkbox-field">
                <input
                  type="checkbox"
                  className="is-checkradio is-small has-background-color"
                  id={`search-checkbox-${item.label}`}
                  name={`search-checkbox-${item.label}`}
                  checked={activeCipCodes.includes(item.value)}
                  onChange={() => onToggleCipCode(item.value)}
                />
                <label htmlFor={`search-checkbox-${item.label}`}>{item.label}</label>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query TopBarQuery {
        allProgramGroup {
          edges {
            node {
              label
              items {
                label
                value
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        allProgramGroup: { edges },
      } = data
      const groups = _.map(edges, 'node').map(group => ({
        ...group,
        searchString: `${group.label};${group.items.map(
          item => `${item.label};${item.value}`
        )}`.toLowerCase(),
      }))
      return <TopBar groups={groups} {...props} />
    }}
  />
)
