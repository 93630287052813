import React, { useState, useEffect } from 'react'
import { IconContext } from 'react-icons'
import { IoIosSearch, IoIosDownload } from 'react-icons/io'
import { FiPercent, FiHash } from 'react-icons/fi'
import _ from 'lodash'
import queryString from 'query-string'
import urlJoin from 'url-join'

import ReactTable from 'react-table-6'

import { columns } from './columnMappings'
import formatFilters from './formatFilters'

import authenticatedRequest from '../lib/authenticatedRequest'
import prefix from '../lib/prefix'
import storage from '../lib/storage'

import withFixedColumns from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css' // important: this line must be placed after react-table css import

import './Table.sass'

const PAGE_SIZE = 20

const ReactTableFixedColumns = withFixedColumns(ReactTable)

export default function UETable(props) {
  /*************************** lifecycle methods ***************************/
  const [tableLoading, setTableLoading] = useState(false)
  const [pages, setPages] = useState(0)
  const [tableData, setTableData] = useState([])
  const [tableConfig, setTableConfig] = useState({ sortBy: 'CTOTALT', tablePage: 0 })
  const [searchTerm, setSearchTerm] = useState('')
  const [usePercentages, setUsePercentages] = useState(false)
  const [showPercentageFormat, setShowPercentageFormat] = useState(false)
  const [totalCounts, setTotalCounts] = useState(null)

  const onSortedChange = (newSorted, column, shiftKey) =>
    setTableConfig({ sortBy: column.id, tablePage: 0 })
  const onPageChange = page => setTableConfig({ tablePage: page, sortBy: tableConfig.sortBy })

  const { attachSchoolInfoToUnitIDs, cipCodes, filters } = props

  const onTextChange = _.debounce(value => setSearchTerm(value), 300)

  const downloadCSV = () => {
    const formattedFilters = formatFilters(filters)
    const qs = queryString.stringify({
      sortBy: 'CTOTALT',
      cipCodes,
      returnAll: true,
      filters: JSON.stringify(formattedFilters),
      percentageBased: usePercentages,
      token: storage.get('token'),
    })
    const url = urlJoin(prefix, 'university-explorer/csv/bySchool')
    window.open(`${url}?${qs}`, '_blank')
  }

  useEffect(() => {
    // if page, sort, filters, or programs changed, we want to refetch
    fetchTableData()

    async function fetchTableData() {
      // show the loading overlay
      setTableLoading(true)

      const formattedFilters = formatFilters(filters)
      // fetch data with the correct route
      const res = await authenticatedRequest({
        extension: '/university-explorer/majors/bySchool',
        responseType: 'json',
        method: 'get',
        params: {
          page: tableConfig.tablePage,
          pageSize: PAGE_SIZE,
          sortBy: tableConfig.sortBy,
          cipCodes,
          // filters: JSON.stringify([["usNewsRanking", 250]]),
          // filters: JSON.stringify([["targetSchools"]]),
          filters: JSON.stringify(formattedFilters),
          search: searchTerm,
          excludeTotals: !!(searchTerm || tableConfig.tablePage !== 0),
          percentageBased: usePercentages,
        },
      })

      // const testRes = await authenticatedRequest({
      //   extension: '/public/speech/liberal',
      //   responseType: 'json',
      //   method: 'get',
      //   params: {
      //     page: tableConfig.tablePage,
      //     pageSize: PAGE_SIZE,
      //     sortBy: tableConfig.sortBy,
      //     filters: JSON.stringify(formattedFilters),
      //     search: searchTerm,
      //   },
      // })
      // console.log(testRes.data)

      setTableData(attachSchoolInfoToUnitIDs(res.data.rows))

      setPages(res.data.pages)

      if (res.data.totalCounts) {
        setTotalCounts(res.data.totalCounts)
      }

      setTableLoading(false)
      setShowPercentageFormat(usePercentages)
    }
  }, [
    tableConfig.sortBy,
    tableConfig.tablePage,
    attachSchoolInfoToUnitIDs,
    cipCodes,
    searchTerm,
    filters,
    usePercentages,
  ])

  return (
    <div>
      <div className="field is-grouped">
        <p className="control is-expanded has-icons-left table-search-bar">
          <input
            className="input"
            type="text"
            placeholder="Search Schools"
            onChange={ev => onTextChange(ev.target.value)}
          />
          <span className="icon is-small is-left">
            <IconContext.Provider value={{ color: '#757575', size: '1em' }}>
              <IoIosSearch />
            </IconContext.Provider>
          </span>
        </p>
        <button className="button is-expanded" style={{ marginRight: 10 }} onClick={downloadCSV}>
          <span class="icon is-small">
            <IconContext.Provider value={{ color: '#757575', size: '1em' }}>
              <IoIosDownload />
            </IconContext.Provider>
          </span>
          <span>Download</span>
        </button>
        <div className="tabs is-toggle">
          <ul>
            <li
              className={usePercentages ? '' : 'is-active'}
              onClick={() => setUsePercentages(false)}
            >
              <a>
                <span className="icon is-small is-left">
                  <IconContext.Provider value={{ className: 'search-icon' }}>
                    <FiHash />
                  </IconContext.Provider>
                </span>
                <span>Counts</span>
              </a>
            </li>
            <li
              className={usePercentages ? 'is-active' : ''}
              onClick={() => setUsePercentages(true)}
            >
              <a>
                <span className="icon is-small is-left">
                  <IconContext.Provider value={{ className: 'search-icon' }}>
                    <FiPercent />
                  </IconContext.Provider>
                </span>
                <span>Percentages</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <ReactTableFixedColumns
        className="schools-table -striped -highlight"
        data={tableConfig.tablePage === 0 && totalCounts ? [totalCounts, ...tableData] : tableData}
        columns={columns(showPercentageFormat)}
        pages={pages}
        pageSize={tableConfig.tablePage === 0 ? PAGE_SIZE + 1 : PAGE_SIZE}
        page={tableConfig.tablePage}
        onPageChange={onPageChange}
        sorted={[{ id: tableConfig.sortBy, desc: true }]}
        onSortedChange={onSortedChange}
        manual
        loading={tableLoading}
        showPageSizeOptions={false}
      />
    </div>
  )
}

// https://github.com/tannerlinsley/react-table/issues/94#issuecomment-389556428
const getColumnWidth = (rows, accessor, headerText) => {
  const maxWidth = 250
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}
